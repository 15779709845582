<template>
    <div class="stepsdemo-content">
        <Card>
            <template v-slot:content>
                <div class="grid p-fluid">
                    <div class="col-12">
                        <div class="grid" v-for="(item, sIndex) in valid$.collection.$model" :key="sIndex">
                            <div class="col-12">
                                <div class="card">
                                    <div class="flex mb-2">
                                        <div class="flex align-items-center">
                                            <InputText class="p-inputtext-sm" type="text" v-model="item.name" />
                                        </div>
                                        <div class="flex flex-grow-1">
                                            <Divider align="right">
                                                <Button icon="pi pi-plus" class="p-button-raised p-button-text p-button-plain p-button-sm" @click="addActivities(sIndex)" label="Add Activity"/>
                                            </Divider>
                                        </div>
                                    </div>
                                    <div class="p-fluid grid" v-for="(activity, index) in item.activities" :key="index">
                                        <div class="field col-12 lg:col-2">
                                            <span class="p-float-label p-fluid">
                                                <Calendar
                                                    :class="{'p-invalid': valid$.collection.$each.$response.$data[sIndex].activities.$each.$data[index].time.$invalid && submitted}"
                                                    id="time"
                                                    v-model="activity.time"
                                                    :timeOnly="true"
                                                    hourFormat="12"
                                                    :showIcon="true"
                                                />
                                                <label for="time">Time <span style="color: #ff4444">*</span></label>
                                            </span>
                                            <span v-if="valid$.collection.$each.$response.$data[sIndex].activities.$each.$data[index].time.$invalid && submitted">
                                                <span
                                                    id="time-error"
                                                    v-for="(error, index) of valid$.collection.$each.$response.$errors[sIndex].activities[0].$response.$errors[index].time"
                                                    :key="index"
                                                >
                                                    <small class="p-error">{{error.$message.replace("Value", "Time")}}</small>
                                                </span>
                                            </span>
                                        </div>
                                        <div class="field col-12 lg:col-4">
                                            <span class="p-float-label p-fluid">
                                                <InputText
                                                    :class="{'p-invalid': valid$.collection.$each.$response.$data[sIndex].activities.$each.$data[index].title.$invalid && submitted}"
                                                    id="title"
                                                    type="text"
                                                    v-model="activity.title"
                                                />
                                                <label for="title">Title <span style="color: #ff4444">*</span></label>
                                            </span>
                                            <span v-if="valid$.collection.$each.$response.$data[sIndex].activities.$each.$data[index].title.$invalid && submitted">
                                                <span
                                                    id="title-error"
                                                    v-for="(error, index) of valid$.collection.$each.$response.$errors[sIndex].activities[0].$response.$errors[index].title"
                                                    :key="index"
                                                >
                                                    <small class="p-error">{{error.$message.replace("Value", "Title")}}</small>
                                                </span>
                                            </span>
                                        </div>
                                        <div class="field col-12 lg:col-6">
                                            <div class="p-inputgroup">
                                                <span class="p-float-label p-fluid">
                                                    <InputText
                                                        :class="{'p-invalid': valid$.collection.$each.$response.$data[sIndex].activities.$each.$data[index].description.$invalid && submitted}"
                                                        id="description"
                                                        type="text"
                                                        v-model="activity.description"
                                                    />
                                                    <Button
                                                        v-if="item.activities.length > 1"
                                                        @click="removeActivities(sIndex, index)"
                                                        icon="pi pi-times"
                                                        class="p-button-raised p-button-danger ml-1"
                                                    />
                                                    <label for="description">Description <span style="color: #ff4444">*</span></label>
                                                </span>
                                            </div>
                                            <span v-if="valid$.collection.$each.$response.$data[sIndex].activities.$each.$data[index].description.$invalid && submitted">
                                                <span
                                                    id="description-error"
                                                    v-for="(error, index) of valid$.collection.$each.$response.$errors[sIndex].activities[0].$response.$errors[index].description"
                                                    :key="index"
                                                >
                                                    <small class="p-error">{{error.$message.replace("Value", "Description")}}</small>
                                                </span>
                                            </span>
                                        </div>
                                    </div>
                                    <div class="flex">
                                        <div class="flex flex-none">
                                            <Button class="p-button p-button-danger" icon="pi pi-times" :label="'Remove ' + item.name" v-if="valid$.collection.$model.length > 1"  @click="removeSchedule(sIndex)"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12">
                                <div class="flex justify-content-center flex-wrap">
                                    <div class="flex flex-none">
                                        <Button class="p-button-success" icon="pi pi-plus" label="Add New Day" v-if="sIndex == valid$.collection.$model.length-1" @click="addSchedule(sIndex)"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
            <template v-slot:footer>
                <div class="flex justify-content-between flex-wrap mb-4">
                    <div class="flex align-items-center justify-content-center">
                        <Button label="Back" @click="prevPage()" icon="pi pi-angle-left" style="width:200px"></Button>
                    </div>
                    <div class="flex align-items-center justify-content-center">
                        <Button label="Next" @click="nextPage(!valid$.$invalid)" icon="pi pi-angle-right" icon-pos="right" style="width:200px"></Button>
                    </div>
                </div>
            </template>
        </Card>
    </div>
</template>

<script>
import { reactive, ref } from 'vue';
import useVuelidate from '@vuelidate/core';
import { required, helpers } from '@vuelidate/validators';

export default {
    setup(){
        const submitted = ref(false);

        const rules = {
            collection: {
                $each: helpers.forEach({
                    day: { required },
                    name: { required },
                    activities: {
                        $each: helpers.forEach({
                            time: { required },
                            title: { required },
                            description: {required}
                        })
                    }
                })
            },
        }
        const state = reactive({
            collection: [
                { 
                    day: 1 , 
                    name: 'Day 1' ,  
                    activities: [
                        {time: '', title:'', description: ''}
                    ]
                }
            ]
        })

        const valid$ = useVuelidate (rules, state);

        const addActivities = (index) => {
            state.collection[index].activities.push(
                {time: '',
                title: '',
                description:''
                }
            );
        };
        const removeActivities = (sIndex, index) => {
            state.collection[sIndex].activities.splice(index, 1);
            
        };
        const addSchedule = (sIndex) => {
            state.collection.push(
                {
                    day: sIndex+2,
                    name: 'Day ' + parseInt(sIndex+2),
                    activities:[
                        {time: '',
                        title: '',
                        description:''
                        }
                    ]}
            );
        };
        const removeSchedule = (sIndex) => {
            if(state.collection.length > sIndex+1){
                for( var i = sIndex+1; i< state.collection.length; i++){
                    state.collection[i].day = i;}
                }
            
            state.collection.splice(sIndex, 1);
        };

        return{
            valid$,
            state,
            submitted,
            addActivities,
            addSchedule,
            removeActivities,
            removeSchedule,
        }
    },
    methods: {
        nextPage(isFormValid) {
            this.submitted = true;
            if (!isFormValid) {
                return;
            }
            this.$emit('next-page', {formData: {
                schedule: this.state.collection
            }, 
            pageIndex: 1});
        },
        prevPage() {
            this.$emit('prev-page', {pageIndex: 1});
        }
    }
}
</script>

<style>

</style>